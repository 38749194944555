<script>
import axios from 'axios'
export default {
  name: 'Auth',
  async created () {
    // on doit mettre dans le token
    const idConnexion = await axios.post('https://oauth.dokoma.com/oauth/access_token.json', { code: this.$route.query.code, client_id: '1c07537c-f984-47cf-8e07-9766ec876602' }).then(({ data }) => {
      return data
    })
    const user = await axios.post('https://oauth.dokoma.com/oauth/user.json', { oauth_token: idConnexion.access_token, client_id: '1c07537c-f984-47cf-8e07-9766ec876602' }).then(({ data }) => {
      return data
    })
    // ON METS DANS LE COOKIE LE IDCONNEXION ET LE USER
    // let token = null
    this.$store.dispatch('User/loginUser', { user, router: this.$router, idConnexion, i18n: this.$i18n })
    // await this.$apollo.mutate({
    //   mutation: require('@/graphql/mutations/login.gql'),
    //   client: 'boutique',
    //   variables: {
    //     provider: user.provider,
    //     uid: user.id
    //   }
    // }).then(({ data }) => {
    //   console.log('retour login', data)
    //   if (data.login) {
    //     this.$store.commit('App/setBearer', data.login)
    //     this.$store.dispatch('User/fetchMinProfile', this)
    //   } else {
    //     this.$store.commit('User/setCheckAccount', true)
    //   }
    //   token = data.login
    // })
    // this.$cookies.set('EcoleDokomaUser', JSON.stringify({ bearer: token, oauth_token: idConnexion.access_token, user, justConnect: true }), 60 * 60 * 24, '/', process.env.COOKIES_HOST)
    // this.$router.push({ name: 'home___' + this.$i18n.locale })
  }
}
</script>

<template lang="pug">
  .auth
    v-progress-circular(indeterminate)
</template>

<style>
</style>
